import { Box, Flex } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

import { CalendarDayPicker } from './CalendarDayPicker';
import { Container } from './Container';
import { Divider } from './Divider';
import { SelectDateTimeText } from './SelectDateTimeText';
import { TimeSelectorDescription } from './TimeSelectorDescription';
import { TimeSlotButtons } from './TimeSlotButtons';
import type { TimeSlotProps } from './types';

export const TimeSelector: FC<TimeSlotProps> = memo((props) => {
  return (
    <Container>
      <TimeSelectorDescription {...props} />
      <Divider />
      <Box>
        <SelectDateTimeText />
        <Flex
          flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']}
          gap={[100, 200, 300]}
          justifyContent="center"
          maxW={['266px', '266px', '266px', 'full']}
        >
          <Box maxW="266px">
            <CalendarDayPicker {...props} />
          </Box>
          <TimeSlotButtons {...props} />
        </Flex>
      </Box>
    </Container>
  );
});
