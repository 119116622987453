import { now } from '@ours/utils';
import { useMemo } from 'react';

import { useDayjs } from './useDayjs';

export const useUsersTimezone = () => {
  const nowTS = useMemo(() => now(), []);

  return useDayjs(nowTS, { format: 'TimezoneLong' });
};
