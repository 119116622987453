import { Box, Flex, MenuItem } from '@chakra-ui/react';
import { Border } from '@ours/utils';
import type { CalendarEvent } from 'calendar-link';
import type { FC } from 'react';
import { memo, useCallback } from 'react';

export interface ILinkItem {
  Icon: FC;
  name: string;
  translator: (ev: CalendarEvent) => string;
}

interface Props extends ILinkItem {
  event: CalendarEvent;
  isFirst: boolean;
  isLast: boolean;
}

export const CalendarLinkItem: FC<Props> = memo(
  ({ Icon, event, isFirst, isLast, name, translator }) => {
    const onClick = useCallback(() => {
      const link = translator(event);
      window.open(link);
    }, [translator, event]);

    return (
      <MenuItem
        _focus={{ bg: 'brand.blue', color: 'white' }}
        _hover={{ bg: 'brand.blue', color: 'white' }}
        borderBottom={isLast ? undefined : Border}
        borderBottomLeftRadius={isLast ? '23px' : undefined}
        borderTopRightRadius={isFirst ? '23px' : undefined}
        color="black"
        data-testid={`menuItem-${name}`}
        onClick={onClick}
        px="200"
        py="100"
        textStyle="md"
      >
        <Flex alignItems="center" flexDir="row" gap="100" justifyContent="start" w="full">
          <Box sx={{ svg: { h: '24px', maxW: '24px' } }}>
            <Icon />
          </Box>
          <Box>Add To {name}</Box>
        </Flex>
      </MenuItem>
    );
  }
);
