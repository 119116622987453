import { Box } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

import { useUsersTimezone } from '../hooks/date/useUsersTimezone';

export interface Props {}

export const TimezoneDescription: FC<Props> = memo(() => {
  const timezone = useUsersTimezone();
  return <Box textStyle="xs">Editing in {timezone}</Box>;
});
