import { Box } from '@chakra-ui/react';
import { RainbowArray, getItemAtRelIndex } from '@ours/utils';
import type { FC } from 'react';
import { memo, useMemo, useState } from 'react';

import { useEvent } from '../../hooks/useEvent';
import { useInterval } from '../../hooks/useInterval';

export interface RainbowTextProps {
  isBold: boolean;
  isMoving?: boolean;
  value: string;
}

export const RainbowText: FC<RainbowTextProps> = memo(({ isBold = true, isMoving, value }) => {
  const [idx, setIdx] = useState(0);
  const values = useMemo(() => {
    let curIndex = idx;
    return value.split('').map((char) => {
      const color = 'brand.' + getItemAtRelIndex(RainbowArray, curIndex);
      if (char !== ' ') {
        curIndex = curIndex + 1;
      }

      return { char, color };
    });
  }, [value, idx]);

  const fn = useEvent(() => {
    if (isMoving) {
      setIdx((i) => i + 1);
    }
  });
  useInterval(fn, 100, true);

  return (
    <Box aria-label={value} as="span" fontWeight={isBold ? '600' : '400'} role="contentinfo">
      {values.map(({ char, color }, i) => (
        <Box
          aria-hidden="true"
          as="span"
          color={color}
          key={`${char}${i}`}
          sx={{ WebkitTextStroke: '.25px black' }}
        >
          {char}
        </Box>
      ))}
    </Box>
  );
});
