import type { StyleProps } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList, useBoolean } from '@chakra-ui/react';
import { Border } from '@ours/utils';
import { Google, ICal, Office365, Outlook, Yahoo } from '@ours/web-icons';
import type { CalendarEvent } from 'calendar-link';
import { google, ics, office365, outlook, yahoo } from 'calendar-link';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import type { ILinkItem } from './lib/CalendarLinkItem';
import { CalendarLinkItem } from './lib/CalendarLinkItem';

export interface Props {
  children?: ReactNode;
  event: CalendarEvent;
  menuButtonProps?: StyleProps & { 'data-testid'?: string };
  triggerText?: string;
}

const ariaTitle = 'Pick the type of calendar to add your event too.';
const calendarOptions: ILinkItem[] = [
  { Icon: ICal, name: 'Cal', translator: ics },
  { Icon: Outlook, name: 'Outlook', translator: outlook },
  { Icon: Google, name: 'Google', translator: google },
  { Icon: Office365, name: 'Office365', translator: office365 },
  { Icon: Yahoo, name: 'Yahoo', translator: yahoo },
];

export const AddToCalendar: FC<Props> = memo(
  ({ children: Button, event, menuButtonProps, triggerText }) => {
    const [isOpen, { off, on }] = useBoolean(false);

    return (
      <Menu isLazy isOpen={isOpen} onClose={off} onOpen={on}>
        <MenuButton aria-label={ariaTitle} title={ariaTitle} {...menuButtonProps}>
          {Button ? Button : triggerText}
        </MenuButton>
        <MenuList
          border={Border}
          borderBottomLeftRadius="700"
          borderBottomRightRadius="0"
          borderTopLeftRadius="0"
          borderTopRightRadius="700"
          minW="0"
          px="0"
          py="0"
        >
          {calendarOptions.map((props, i) => (
            <CalendarLinkItem
              event={event}
              isFirst={i === 0}
              isLast={i === calendarOptions.length - 1}
              key={props.name}
              {...props}
            />
          ))}
        </MenuList>
      </Menu>
    );
  }
);
