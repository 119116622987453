import type { DayJSInput } from '@ours/utils';
import { DateFormats, dayjs } from '@ours/utils';
import { useEffect, useState } from 'react';
import ago from 's-ago';

import { useEvent } from '../useEvent';
import { useInterval } from '../useInterval';

export const useDayjs = (dateOrTime: DayJSInput, options?: IOptions) => {
  const [response, setResponse] = useState('');

  const rerender = useEvent(() => {
    if (!dateOrTime) {
      return setResponse(options?.fallbackText || '');
    }
    const d = options?.isUTC ? dayjs.utc : dayjs;
    if (options?.isAgo) {
      return setResponse(ago(d(dateOrTime).toDate()));
    }
    const formatStr = options?.format || 'MM/DD/YYYY';
    const formatStrKey =
      formatStr in DateFormats
        ? // @ts-expect-error idk
          DateFormats[formatStr]
        : formatStr;

    const next = d(dateOrTime).format(formatStrKey);
    if (next !== response) {
      setResponse(next);
    }
  });

  useEffect(() => {
    rerender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOrTime]);

  useInterval(rerender, 1000, true);

  return response;
};

export interface IOptions {
  fallbackText?: string;
  /**
   * Get the formatted date according to the string of tokens passed in.
   *
   * Common formats: MM/DD/YYYY | MM/DD/YYYY hh:mm a
   *
   * To escape characters, wrap them in square brackets (e.g. [MM]).
   * ```
   * dayjs().format()// => current date in ISO8601, without fraction seconds e.g. '2020-04-02T08:02:17-05:00'
   * dayjs('2019-01-25').format('[YYYYescape] YYYY-MM-DDTHH:mm:ssZ[Z]')// 'YYYYescape 2019-01-25T00:00:00-02:00Z'
   * dayjs('2019-01-25').format('DD/MM/YYYY') // '25/01/2019'
   * ```
   * Docs: https://day.js.org/docs/en/display/format
   */
  format?: keyof typeof DateFormats | string;
  isAgo?: boolean;
  isUTC?: boolean;
}
