import type { TimeSlot } from '../generated/operations';
import { first } from '../lang/first';

import { dayjs } from './dayjs';

export const findMinMaxDates = (
  timeSlots: readonly Pick<TimeSlot, 'endDateTime' | 'startDateTime'>[]
) => {
  let minDate = dayjs(first(timeSlots)?.startDateTime);
  let maxDate = dayjs(first(timeSlots)?.endDateTime);

  timeSlots.forEach(({ startDateTime }) => {
    const instance = dayjs(startDateTime);
    if (instance.isAfter(maxDate)) {
      maxDate = instance;
    }

    if (instance.isBefore(minDate)) {
      minDate = instance;
    }
  });

  return { maxDate: maxDate.toDate(), minDate: minDate.toDate() };
};
