import { useBoolean } from '@chakra-ui/react';
import { useMemo, useState } from 'react';

import { useEvent } from './useEvent';

export const useAsyncEvent = <Func extends (...args: any[]) => Promise<any>>(
  action: Func
): [Func, boolean, unknown] => {
  const [loading, { off, on }] = useBoolean(false);
  const [error, setError] = useState<null | unknown>(null);

  // @ts-expect-error Func
  const act: Func = useEvent(async (...args) => {
    let res;
    on();
    try {
      res = await action(...args);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setError(error);
    }

    off();
    return res;
  });

  return useMemo(() => [act, loading, error], [act, error, loading]);
};
