import { Box, Center } from '@chakra-ui/react';
import type { Maybe } from '@ours/types';
import type { TimeSlot } from '@ours/utils';
import type { FC } from 'react';
import { memo, useCallback, useEffect, useState } from 'react';

import { useAnalyticsEvent } from '../../hooks/analytics/useAnalyticsEvent';
import { useBrandedToast } from '../../hooks/useBrandedToast';
import { useEvent } from '../../hooks/useEvent';
import { SquiggleLoader } from '../FullCenteredSquiggleLoader/lib/SquiggleLoader';

import { Container } from './lib/Container';
import { DateSelector } from './lib/DateSelector';
import { RootContainer } from './lib/RootContainer';
import { SuccessfulSchedule } from './lib/SuccessfulSchedule';
import { TimeSelector } from './lib/TimeSelector';
import { calendarStaticH } from './lib/constants';
import type { TimeSlotProps, TimeSlotSelectorState } from './lib/types';
import { useDateMap } from './lib/useDateMap';
import { useMinMaxDates } from './lib/useMinMaxDates';

export interface TimeSlotSelectorProps {
  description: Maybe<TimeSlotProps['description']>;
  durationInMinutes: Maybe<TimeSlotProps['durationInMinutes']>;
  loading: boolean;
  onConfirmSelection: (timeSlot: TimeSlot) => void | Promise<void>;
  /**
   * We are calculating things locally with timeSlots. This could become a bottleneck.
   *
   * But, we expect a max of 2160 timeSlots (45 days * 12 hr * 4 slots per hour)
   */
  timeSlots: readonly TimeSlot[];
  title: TimeSlotProps['title'];
}

const lookup: Record<TimeSlotSelectorState, FC<TimeSlotProps>> = {
  dateSelector: DateSelector,
  success: SuccessfulSchedule,
  timeSelector: TimeSelector,
};

export const TimeSlotSelector: FC<TimeSlotSelectorProps> = memo(
  ({ description, durationInMinutes, loading, onConfirmSelection, timeSlots, ...props }) => {
    const [state, setState] = useState<TimeSlotSelectorState>('dateSelector');
    const [timeSlot, setTimeSlot] = useState<TimeSlot | null>(null);
    const [initialDateTime, setInitialDateTime] = useState<TimeSlot | null>(null);
    const [date, setDate] = useState<Date | null>(null);
    const isEmpty = !loading && timeSlots.length === 0;
    const Component = lookup[state];
    const toast = useBrandedToast();
    const { maxDate, minDate } = useMinMaxDates(timeSlots);
    const dateMap = useDateMap(timeSlots);
    const { trackEvent } = useAnalyticsEvent();

    useEffect(() => {
      trackEvent({ type: 'Scheduler_Init' });
    }, [trackEvent]);

    const onSelectTimeSlot: TimeSlotProps['onSelectTimeSlot'] = useEvent((timeSlot: TimeSlot) => {
      setInitialDateTime(timeSlot);
      setTimeSlot(timeSlot);
      trackEvent({ type: 'Scheduler_Select_Time' });
    });

    // IMPORTANT: THIS NEEDS TO BE A useCallback. It is passed to the Calendar
    const excludeDate: TimeSlotProps['excludeDate'] = useCallback(
      (date) => {
        const str = `${date.getMonth()}/${date.getDate()}`;

        return dateMap[str] !== true;
      },
      [dateMap]
    );

    const onSelectDate: TimeSlotProps['onSelectDate'] = useEvent((date) => {
      setDate(date);

      setState('timeSelector');
      setInitialDateTime(null);
      trackEvent({ type: 'Scheduler_Select_Date' });
    });

    const onConfirmation: TimeSlotProps['onConfirmation'] = useEvent(async () => {
      if (!timeSlot) {
        return toast({ title: 'Time slot is requested' });
      }

      await onConfirmSelection(timeSlot);
      setInitialDateTime(null);
      setState('success');
    });

    return (
      <RootContainer>
        {loading ? (
          <Container>
            <Center h={calendarStaticH}>
              <SquiggleLoader />
            </Center>
          </Container>
        ) : isEmpty ? (
          <Box>There is no availability</Box>
        ) : (
          <Component
            date={date}
            description={description || ''}
            durationInMinutes={durationInMinutes || 45}
            excludeDate={excludeDate}
            initialTimeSlot={initialDateTime}
            maxDate={maxDate}
            minDate={minDate}
            onConfirmation={onConfirmation}
            onSelectDate={onSelectDate}
            onSelectTimeSlot={onSelectTimeSlot}
            timeSlot={timeSlot}
            timeSlots={timeSlots}
            {...props}
          />
        )}
      </RootContainer>
    );
  }
);
