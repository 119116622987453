import { Box } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

export interface Props {}

export const TodayDot: FC<Props> = memo(() => {
  return (
    <Box
      bg="blue.300"
      borderRadius="full"
      bottom="4px"
      h="5px"
      left="50%"
      pos="absolute"
      transform="translate(-50%, 0%)"
      w="5px"
    />
  );
});
