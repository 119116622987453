import { Box, Flex } from '@chakra-ui/react';
import { Calendar } from '@mantine/dates';
import { dayjs } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

import { useEvent } from '../../../hooks/useEvent';

import { TodayDot } from './TodayDot';
import type { TimeSlotProps } from './types';

export const CalendarDayPicker: FC<TimeSlotProps> = memo(
  ({ date, excludeDate, maxDate, minDate, onSelectDate }) => {
    const renderDay = useEvent((date: Date) => {
      const inst = dayjs(date);
      const isToday = inst.isToday();

      return (
        <Box data-testid={`Day.${inst.date()}`} pos="relative">
          {date.getDate()}
          {isToday ? <TodayDot /> : null}
        </Box>
      );
    });

    return (
      <Flex flexDir="column" gap={[50, 100, 200]}>
        <Calendar
          allowLevelChange={false}
          excludeDate={excludeDate}
          firstDayOfWeek="sunday"
          initialMonth={minDate}
          maxDate={maxDate}
          minDate={minDate}
          onChange={onSelectDate}
          renderDay={renderDay}
          value={date}
        />
      </Flex>
    );
  }
);
