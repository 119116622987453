import type { SystemStyleObject } from '@chakra-ui/react';
import { Box, Center, Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { useInputState } from '@mantine/hooks';
import { useMachine } from '@xstate/react';
import type { FC, FormEvent } from 'react';
import { memo } from 'react';

import { useEvent } from '../../hooks/useEvent';
import { Button } from '../Buttons/Button';
import { Textarea } from '../Inputs/Textarea';
import { RainbowText } from '../Rainbows/RainbowText';

import { StarIcon } from './StarIcon';
import type { RatingStates } from './ratingMachine';
import { ratingMachine } from './ratingMachine';

export interface StarRatingProps {
  onNumericRating: (num: number) => void;
  onWhyNegative: (why: string) => void;
  question: string;
}
const css: SystemStyleObject = {
  '.s1:hover ~ svg': {
    fill: 'brand.pink',
  },
  '.s2:hover ~ svg': {
    fill: 'brand.pink',
  },
  '.s3:hover ~ svg': {
    fill: 'brand.pink',
  },
  '.s4:hover ~ svg': {
    fill: 'brand.pink',
  },
  '.s5:hover ~ svg': {
    fill: 'brand.pink',
  },
  svg: {
    _hover: {
      fill: 'brand.pink',
      transform: 'scale(1.1)',
    },
    cursor: 'pointer',
    maxW: '34px',
    transition: 'all 0.3s',
  },
};

export const StarRating: FC<StarRatingProps> = memo(
  ({ onNumericRating, onWhyNegative, question }) => {
    const [value, setValue] = useInputState('');
    const [state, send] = useMachine(ratingMachine, {
      context: { onNumericRating, onWhyNegative },
    });
    const stateValue = state.value as RatingStates;

    const onRate = useEvent((rating: number) => {
      send({ rating, type: 'RATE' });
    });
    const onSkip = useEvent(() => {
      send({ type: 'SKIP' });
    });
    const onSubmitExtra = useEvent((e: FormEvent) => {
      e.preventDefault();
      send({ feedback: value, type: 'SAVE_FEEDBACK' });
    });

    if (stateValue === 'init') {
      return (
        <Box p="100" w="250px">
          <FormLabel textAlign="center">{question}</FormLabel>

          <Flex dir="rtl" justifyContent="space-between" sx={css} w="full">
            <StarIcon className="s5" onRate={onRate} rating={5} />
            <StarIcon className="s4" onRate={onRate} rating={4} />
            <StarIcon className="s3" onRate={onRate} rating={3} />
            <StarIcon className="s2" onRate={onRate} rating={2} />
            <StarIcon className="s1" onRate={onRate} rating={1} />
          </Flex>
        </Box>
      );
    } else if (stateValue === 'askWhy') {
      return (
        <Flex as="form" flexDir="column" gap="100" onSubmit={onSubmitExtra} w="250px">
          <FormControl>
            <FormLabel>Oh no! What made you rate {state.context.rating}?</FormLabel>
            <Textarea onChange={setValue} size="sm" value={value} />
          </FormControl>
          <Flex gap="200" justifyContent="flex-end">
            <Button as="button" onClick={onSkip} size="sm" type="button" variant="beige">
              Skip
            </Button>
            <Button as="button" size="sm" type="submit">
              Save
            </Button>
          </Flex>
        </Flex>
      );
    } else if (stateValue === 'thankYou') {
      return (
        <Center w="250px">
          <RainbowText isBold isMoving value="Thank you!" />
        </Center>
      );
    } else if (stateValue === 'done') {
      return (
        <Center w="250px">
          <RainbowText isBold value="Thank you!" />
        </Center>
      );
    }

    return null;
  }
);

/**
 *
 * @see https://codepen.io/Bilal1909/pen/WNGXeQG
.star-wrapper {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  direction: rtl;
}
.star-wrapper a {
  font-size: 4em;
  color: #fff;
  text-decoration: none;
  transition: all 0.5s;
  margin: 4px;
}
.star-wrapper a:hover {
  color: brand.pink;
  transform: scale(1.3);
}
.s1:hover ~ a {
  color: brand.pink;
}
.s2:hover ~ a {
  color: brand.pink;
}
.s3:hover ~ a {
  color: brand.pink;
}
.s4:hover ~ a {
  color: brand.pink;
}
.s5:hover ~ a {
  color: brand.pink;
}
.wraper {
  position: absolute;
  bottom: 30px;
  right: 50px;
}

 */
