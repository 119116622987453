import { Box, Flex, Text } from '@chakra-ui/react';
import { Calendar } from '@ours/web-icons';
import type { FC } from 'react';
import { memo } from 'react';

import { TimezoneDescription } from '../../TimezoneDescription';

import type { TimeSlotProps } from './types';

export const TimeSelectorDescription: FC<TimeSlotProps> = memo((props) => {
  return (
    <Flex flexDir="column" gap={[100, 100, 200]} minW="300px" w="30%">
      <Text data-testid="TimeSelectorDescription.Title" fontWeight="600" textStyle="xl">
        {props.title}
      </Text>
      <Flex alignItems="center" gap="100">
        <Box as="span">
          <Calendar />
        </Box>
        <Text textStyle="md">{props.durationInMinutes} min</Text>
      </Flex>
      <Text textStyle={{ base: '2xs', md: 'xs' }}>{props.description}</Text>
      <TimezoneDescription />
    </Flex>
  );
});
