import { Box } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

export interface Props {
  children: ReactNode;
}

export const RootContainer: FC<Props> = memo(({ children }) => {
  return (
    <Box
      sx={{
        '.mantine-157z7sm.mantine-Calendar-cell button:not(:disabled)': { color: 'inherit' },
        '.mantine-1f8v7sc[data-selected]': { color: '#fff!important' },
      }}
    >
      {children}
    </Box>
  );
});
