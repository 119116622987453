export const DateFormats = {
  AWSScheduler: 'YYYY-MM-DDTHH:mm:ss',
  DatabaseDate: 'YYYY-MM-DD',
  Date: 'MM/DD/YYYY',
  DateTimeAM: 'MM/DD/YYYY h:mm a',
  DateTimeSecondsAM: 'MM/DD/YYYY h:mm:ss a',
  DateTimeZone: 'MM/DD/YYYY h:mm a z',
  DayMonthDate: 'dddd, MMMM D',
  EmailFormat: 'dddd, MMMM, DD, YYYY [at] h:mma z',
  HoursMin: 'h:mma',
  HoursMinNoZone: 'h:mm',
  LongFormattedDateTime: 'ddd MMM, YYYY h:mma z',
  LongMonthWithTime: 'MMMM D [at] h:mm z',
  MonthDayTime: 'MMM DD h:mma z',
  PrintedDate: 'MMMM D YYYY',
  PrintedDay: 'dddd M/D',
  Timezone: 'z',
  TimezoneLong: 'zzz (z)',
};
