import type { FC } from 'react';
import { memo } from 'react';

import { useEvent } from '../../hooks/useEvent';

export interface StarIconProps {
  className?: string;
  onRate: (num: number) => void;
  rating: number;
}

export const StarIcon: FC<StarIconProps> = memo(({ className, onRate, rating }) => {
  const onRateLocal = useEvent(() => {
    onRate(rating);
  });

  return (
    <svg
      className={className}
      fill="none"
      height="49"
      onClick={onRateLocal}
      viewBox={`0 0 47 49`}
      width="47"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4169 2.42423C23.1368 0.525257 25.8615 0.525257 26.5837 2.42423L31.241 15.3255C31.4035 15.7458 31.6896 16.1069 32.0615 16.3612C32.4335 16.6155 32.8738 16.7509 33.3244 16.7498H44.7696C46.8845 16.7498 47.807 19.3822 46.1443 20.6715L37.9997 27.9996C37.6348 28.2801 37.3682 28.6689 37.2381 29.1103C37.1079 29.5517 37.1209 30.023 37.2752 30.4566L40.2496 43.0631C40.974 45.0881 38.6296 46.8273 36.8567 45.5808L25.794 38.5609C25.4151 38.2947 24.9633 38.1518 24.5003 38.1518C24.0372 38.1518 23.5854 38.2947 23.2066 38.5609L12.1438 45.5808C10.3732 46.8273 8.0265 45.0858 8.75097 43.0631L11.7253 30.4566C11.8796 30.023 11.8926 29.5517 11.7625 29.1103C11.6323 28.6689 11.3657 28.2801 11.0009 27.9996L2.85623 20.6715C1.19131 19.3822 2.11827 16.7498 4.22867 16.7498H15.6739C16.1246 16.7513 16.565 16.6159 16.937 16.3616C17.3091 16.1073 17.5951 15.746 17.7573 15.3255L22.4146 2.42423H22.4169Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
});
