import type { TimeSlot } from '@ours/utils';
import { dayjs } from '@ours/utils';
import { useMemo } from 'react';

export const useDateMap = (timeSlots: readonly TimeSlot[]) => {
  return useMemo(() => {
    return timeSlots.reduce((acc, cur) => {
      if (!cur.startDateTime) {
        return acc;
      }

      const instance = dayjs(cur.startDateTime);
      acc[`${instance.month()}/${instance.date()}`] = true;
      return acc;
    }, {} as Record<string, true>);
  }, [timeSlots]);
};
