import type { FC } from 'react';
import { memo } from 'react';

interface Props {}

export const Google: FC<Props> = memo(() => {
  return (
    <svg fill="none" height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M29.4 15.3416C29.4 14.278 29.3046 13.2553 29.1273 12.2734H15V18.0757H23.0727C22.725 19.9507 21.6682 21.5394 20.0796 22.603V26.3666H24.9273C27.7637 23.7553 29.4 19.9098 29.4 15.3416Z"
        fill="#4285F4"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M14.9993 30.0016C19.0493 30.0016 22.4448 28.6584 24.9266 26.3675L20.0789 22.6038C18.7357 23.5038 17.0175 24.0357 14.9993 24.0357C11.0925 24.0357 7.78565 21.397 6.6061 17.8516H1.59473V21.7379C4.06291 26.6402 9.13565 30.0016 14.9993 30.0016Z"
        fill="#34A853"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6.60683 17.8481C6.30683 16.9481 6.13638 15.9867 6.13638 14.9981C6.13638 14.0095 6.30683 13.0481 6.60683 12.1481V8.26172H1.59546C0.579547 10.2867 0 12.5776 0 14.9981C0 17.4186 0.579547 19.7095 1.59546 21.7345L6.60683 17.8481Z"
        fill="#FBBC05"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M14.9993 5.96592C17.2016 5.96592 19.1789 6.72274 20.7334 8.20911L25.0357 3.90683C22.438 1.48637 19.0425 0 14.9993 0C9.13565 0 4.06291 3.36137 1.59473 8.26365L6.6061 12.15C7.78565 8.60456 11.0925 5.96592 14.9993 5.96592Z"
        fill="#EA4335"
        fillRule="evenodd"
      />
    </svg>
  );
});
