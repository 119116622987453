import type { ToXStateSchema } from '@ours/types';
import { assertType, noopThrow } from '@ours/utils';
import type { MachineConfig } from 'xstate';
import { assign, createMachine } from 'xstate';

interface RatingContext {
  feedback: string | undefined;
  onNumericRating: (num: number) => void;
  onWhyNegative: (why: string) => void;
  rating: number;
}

const baseContext: RatingContext = {
  feedback: undefined,
  onNumericRating: noopThrow('onNumericRating'),
  onWhyNegative: noopThrow('onWhyNegative'),
  rating: -1,
};

type RateAction = { rating: number; type: 'RATE' };
type SkipAction = { type: 'SKIP' };
type FeedbackAction = { feedback: string; type: 'SAVE_FEEDBACK' };
type Actions = RateAction | FeedbackAction | SkipAction;

export const RatingStates = ['init', 'done', 'thankYou', 'askWhy', 'onRating'] as const;
export type RatingStates = (typeof RatingStates)[number];

const ratingMachineSchema: MachineConfig<
  RatingContext,
  ToXStateSchema<typeof RatingStates>,
  Actions
> = {
  context: baseContext,
  description: '',
  id: 'ratingActions',
  initial: 'init',
  predictableActionArguments: true,
  states: {
    askWhy: {
      on: {
        SAVE_FEEDBACK: { actions: 'saveFeedback', target: 'thankYou' },
        SKIP: { target: 'thankYou' },
      },
    },
    done: { type: 'final' },
    init: {
      on: {
        RATE: { actions: 'saveRating', target: 'onRating' },
      },
    },
    onRating: {
      always: [
        {
          cond: 'askWhy',
          target: 'askWhy',
        },
        { target: 'thankYou' },
      ],
    },
    thankYou: {
      after: {
        3000: { target: 'done' },
      },
    },
  },
};

export const ratingMachine = createMachine(ratingMachineSchema, {
  actions: {
    saveFeedback: assign((_, event) => {
      assertType(event, 'SAVE_FEEDBACK');
      _.onWhyNegative(event.feedback);
      return { feedback: event.feedback };
    }),
    saveRating: assign((_, event) => {
      assertType(event, 'RATE');
      _.onNumericRating(event.rating);
      return { rating: event.rating };
    }),
  },
  guards: {
    askWhy: (ctx) => ctx.rating <= 3,
  },
});
