import { Flex } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

export interface Props {
  children: ReactNode;
}

export const Container: FC<Props> = memo(({ children }) => {
  return (
    <Flex
      alignItems="start"
      flexWrap="wrap"
      gap={[100, 200, 300]}
      justifyContent="center"
      p={[100, 100, 200, 300]}
    >
      {children}
    </Flex>
  );
});
