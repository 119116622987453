import { Box, Flex, Text } from '@chakra-ui/react';
import { cloneDeep, dayjs, isSameDate } from '@ours/utils';
import type { FC } from 'react';
import { memo, useMemo } from 'react';

import { useDayjs } from '../../../hooks/date/useDayjs';

import { TimeButtonDisplay } from './TimeButtonDisplay';
import { calendarStaticH } from './constants';
import type { TimeSlotProps } from './types';

export const TimeSlotButtons: FC<TimeSlotProps> = memo(
  ({ date, initialTimeSlot, onConfirmation, onSelectTimeSlot, timeSlots }) => {
    const timeSlotsOnDate = useMemo(() => {
      if (!date) {
        return [];
      }
      const instance = dayjs(date);
      const filtered = timeSlots.filter((slot) => isSameDate(instance, dayjs(slot.startDateTime)));
      return cloneDeep(filtered).sort((a, b) => {
        return dayjs(a.startDateTime).isAfter(dayjs(b.startDateTime)) ? 1 : -1;
      });
    }, [timeSlots, date]);

    const formattedDate = useDayjs(date, { format: 'DayMonthDate' });

    if (!date) {
      return null;
    }

    return (
      <Box minW="236px" overflow="hidden">
        <Text pb={[50, 100]} pt="12px" textStyle="xs">
          {formattedDate}
        </Text>

        <Flex
          alignItems="start"
          flexDir="column"
          gap="100"
          maxH={calendarStaticH}
          overflowY="scroll"
          p="50"
          pr="100"
          w="full"
        >
          {timeSlotsOnDate.map((timeSlot) => (
            <TimeButtonDisplay
              beingConfirmed={timeSlot.startDateTime === initialTimeSlot?.startDateTime}
              key={timeSlot.startDateTime}
              onConfirmation={onConfirmation}
              onSelectTimeSlot={onSelectTimeSlot}
              timeSlot={timeSlot}
            />
          ))}
        </Flex>
      </Box>
    );
  }
);
