import { Box, Flex, Text } from '@chakra-ui/react';
import { dayjs } from '@ours/utils';
import type { FC } from 'react';
import { memo, useMemo } from 'react';

import { useAnalyticsEvent } from '../../../hooks/analytics/useAnalyticsEvent';
import { useDayjs } from '../../../hooks/date/useDayjs';
import { useEvent } from '../../../hooks/useEvent';
import { AddToCalendar } from '../../AddToCalendar/AddToCalendar';
import { Button } from '../../Buttons/Button';
import { UNDERLINE_LINK_PROPS } from '../../Links/underlineLinkProps';
import { StarRating } from '../../StarRating/StarRating';

import { Container } from './Container';
import type { TimeSlotProps } from './types';

export type Props = {
  durationInMinutes: number;
  timeSlot: TimeSlotProps['timeSlot'];
  title: string;
};

export const SuccessfulSchedule: FC<Props> = memo(({ durationInMinutes, timeSlot, title }) => {
  const meetingTimeDesc = useDayjs(timeSlot?.startDateTime, { format: 'DateTimeSecondsAM' });
  const endTime = useMemo(
    () => dayjs(timeSlot?.startDateTime).add(durationInMinutes, 'minutes').toISOString(),
    [durationInMinutes, timeSlot]
  );

  const { trackEvent } = useAnalyticsEvent();
  const onNumericRating = useEvent((rating: number) => {
    trackEvent({ payload: { rating }, type: 'SCHEDULING_AVAILABILITY_RATING' });
  });
  const onWhyNegative = useEvent((feedback: string) => {
    trackEvent({ payload: { feedback }, type: 'SCHEDULING_AVAILABILITY_FEEDBACK' });
  });

  return (
    <Container>
      <Flex flexWrap={{ base: 'wrap', md: 'nowrap' }} gap={{ base: '300', md: '100' }} w="full">
        <Flex
          flexDir="column"
          gap="100"
          textAlign={{ base: 'center', md: 'left' }}
          w={{ base: 'full', md: '50%' }}
        >
          <Text fontWeight="600" textStyle="md">
            {title} Confirmed
          </Text>
          <Text textStyle="xs">
            You are scheduled for your {title} on {meetingTimeDesc}! We’re so excited for you to
            meet with you.
          </Text>
          <Box>
            <AddToCalendar
              event={{
                duration: [durationInMinutes, 'minutes'],
                end: endTime,
                location: '',
                start: timeSlot?.startDateTime,
                title: title || '',
              }}
              menuButtonProps={{ textStyle: '2xs', ...UNDERLINE_LINK_PROPS }}
              triggerText="Add to calendar"
            >
              <Button as="div" size="sm">
                Add to calendar
              </Button>
            </AddToCalendar>
          </Box>
        </Flex>
        <Flex
          alignItems={{ base: 'center', md: 'start' }}
          justifyContent="center"
          mt="-10px"
          w={{ base: 'full', md: '50%' }}
        >
          <StarRating
            onNumericRating={onNumericRating}
            onWhyNegative={onWhyNegative}
            question="How satisfied are you with our availability?"
          />
        </Flex>
      </Flex>
    </Container>
  );
});
