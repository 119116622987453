import { Box, HStack } from '@chakra-ui/react';
import type { TimeSlot } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

import { useDayjs } from '../../../hooks/date/useDayjs';
import { useAsyncEvent } from '../../../hooks/useAsyncEvent';
import { useEvent } from '../../../hooks/useEvent';
import { Button } from '../../Buttons/Button';

export interface Props {
  beingConfirmed: boolean;
  onConfirmation: () => Promise<void>;
  onSelectTimeSlot: (timeSlot: TimeSlot) => void;
  timeSlot: TimeSlot;
}

export const TimeButtonDisplay: FC<Props> = memo(
  ({ beingConfirmed, onConfirmation, onSelectTimeSlot, timeSlot }) => {
    const start = useDayjs(timeSlot?.startDateTime, { format: 'HoursMin' });
    const [onConfirmClick, loading] = useAsyncEvent(async () => {
      await onConfirmation();
    });

    const onClick = useEvent(() => {
      onSelectTimeSlot(timeSlot);
    });

    return (
      <HStack spacing={50} w="full">
        <Box w="100px">
          <Button
            as="button"
            onClick={onClick}
            size="smFullW"
            testId={`TimeSlot.${timeSlot.startDateTime}`}
            type="button"
            variant="beige"
            w="full"
          >
            {start}
          </Button>
        </Box>
        {beingConfirmed ? (
          <Button
            as="button"
            isLoading={loading}
            onClick={onConfirmClick}
            size="sm"
            testId="TimeButtonDisplay.Confirm"
            type="button"
            variant="blue"
          >
            Confirm
          </Button>
        ) : null}
      </HStack>
    );
  }
);
