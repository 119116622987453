import { Box } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

import { CalendarDayPicker } from './CalendarDayPicker';
import { Container } from './Container';
import { Divider } from './Divider';
import { SelectDateTimeText } from './SelectDateTimeText';
import { TimeSelectorDescription } from './TimeSelectorDescription';
import type { TimeSlotProps } from './types';

export const DateSelector: FC<TimeSlotProps> = memo((props) => {
  return (
    <Container>
      <TimeSelectorDescription {...props} />
      <Divider />
      <Box>
        <SelectDateTimeText />
        {/* aka: the mn with the most rows */}
        <Box maxW="266px" minH="313px">
          <CalendarDayPicker {...props} />
        </Box>
      </Box>
    </Container>
  );
});
