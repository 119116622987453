import { Text } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

export interface Props {}

export const SelectDateTimeText: FC<Props> = memo(() => {
  return (
    <Text pb={[50, 100]} textStyle="xs">
      Select a Date & Time
    </Text>
  );
});
