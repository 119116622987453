/* eslint-disable @typescript-eslint/no-namespace */
import _dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

declare module 'dayjs' {
  interface Dayjs {
    isToday(): boolean;
    isUTC(): boolean;
    local(): _dayjs.Dayjs;
    utc(keepLocalTime?: boolean): _dayjs.Dayjs;
    utcOffset(offset: number | string, keepLocalTime?: boolean): _dayjs.Dayjs;
  }

  export function utc(config?: _dayjs.ConfigType, format?: string, strict?: boolean): _dayjs.Dayjs;
}

_dayjs.extend(advancedFormat);
_dayjs.extend(utc);
_dayjs.extend(timezone);
_dayjs.extend(isToday);
_dayjs.extend(isBetween);
export const dayjs = _dayjs;
