import type { FC } from 'react';
import { memo } from 'react';

interface Props {}

export const Calendar: FC<Props> = memo(() => {
  return (
    <svg fill="none" height="49" viewBox="0 0 44 49" width="44" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.28577 6.11328H1.28577V7.11328V26.8279V26.8279C1.28594 32.3216 3.46844 37.5903 7.35316 41.4749C11.2379 45.3595 16.5066 47.5418 22.0004 47.5418C27.494 47.5417 32.7626 45.3593 36.6472 41.4747C40.5318 37.5901 42.7142 32.3215 42.7143 26.8279V7.11328V6.11328H41.7143H2.28577Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M29.4246 1.56858H28.4246V2.56858V9.88395C28.4246 10.6185 28.7161 11.3231 29.2352 11.8429C29.7542 12.3627 30.4584 12.6552 31.193 12.6562V12.6562H31.1944C31.5584 12.6562 31.9189 12.5845 32.2553 12.4452C32.5916 12.3059 32.8972 12.1017 33.1547 11.8443C33.4121 11.5868 33.6163 11.2812 33.7556 10.9449C33.8949 10.6085 33.9666 10.248 33.9666 9.88395V2.56858V1.56858H32.9666H29.4246Z"
        fill="#FFAB00"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M9.88286 8.88786H9.88037V9.88786C9.88037 10.6231 10.1725 11.3283 10.6924 11.8482C11.2123 12.3681 11.9174 12.6602 12.6527 12.6602C13.0167 12.6602 13.3772 12.5884 13.7136 12.4491L13.3309 11.5252L13.7136 12.4491C14.0499 12.3098 14.3555 12.1056 14.613 11.8482L13.928 11.1632L14.613 11.8482C14.8704 11.5907 15.0746 11.2851 15.2139 10.9488C15.3533 10.6124 15.425 10.2519 15.425 9.88786V2.57V1.57H14.425H10.8829H9.88286V2.57V8.88786Z"
        fill="#FFAB00"
        stroke="black"
        strokeWidth="2"
      />
      <path d="M6.71802 15.0195H37.1335" stroke="black" strokeWidth="2" />
      <path d="M16.8212 19.2266V40.7439" stroke="black" strokeWidth="2" />
      <path d="M27.2015 19.2266V40.7439" stroke="black" strokeWidth="2" />
      <path d="M11.167 24.7969H32.6843" stroke="black" strokeWidth="2" />
      <path d="M32.6843 35.1758H11.167" stroke="black" strokeWidth="2" />
    </svg>
  );
});
