import type { TextareaProps } from '@chakra-ui/react';
import { Textarea as ChakraTextarea, Skeleton } from '@chakra-ui/react';
import { Colors } from '@ours/utils';
import { forwardRef, memo } from 'react';

const variants = {
  base: {
    bg: 'brand.beige',
    borderColor: 'black',
    focusColor: Colors.brand.blue,
    invalidColor: Colors.ui.error,
  },
  white: {
    bg: 'white',
    borderColor: 'black',
    focusColor: Colors.brand.blue,
    invalidColor: Colors.ui.error,
  },
} as const;

export interface TextAreaProps extends TextareaProps {
  // eslint-disable-next-line react/no-unused-prop-types
  loading?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  variant?: keyof typeof variants;
}

export const Textarea = memo(
  forwardRef<any, TextAreaProps>(({ loading, variant = 'base', ...props }, ref) => {
    const { bg, borderColor, focusColor, invalidColor } = variants[variant];
    if (loading) {
      return <Skeleton h="155px" />;
    }
    return (
      <ChakraTextarea
        {...props}
        _focus={{ borderColor: focusColor }}
        _hover={{ borderColor }}
        _invalid={{ borderColor: invalidColor }}
        bg={bg}
        borderBottomLeftRadius="8px"
        borderBottomRightRadius="0px"
        borderColor={borderColor}
        borderTopLeftRadius="0px"
        borderTopRightRadius="8px"
        borderWidth="2px"
        ref={ref}
      />
    );
  })
);
